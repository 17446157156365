import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getEnvironment } from "./Environment";

const BocomboForm = ({ addTask, initValues, textAreaWidth }) => {
  const [element, setElement] = useState(initValues.task);
  const [location, setLocation] = useState(initValues.location);
  const [resolution, setResolution] = useState(initValues.resolution);

  console.log(textAreaWidth);
  const buttonTitle = initValues.task === "" ? "Hinzufügen" : "Speichern";

  const handleChange1 = (e) => {
    setElement(e.currentTarget.value);
  };

  const handleChange2 = (e) => {
    setLocation(e.currentTarget.value);
  };

  const handleChange3 = (e) => {
    setResolution(e.currentTarget.value);
  };

  const handleSubmit = () => {
    addTask(element, location, resolution);
    setElement("");
    setLocation("");
    setResolution("");
  };

  const env = getEnvironment();

  let task_box1 =
    env.language && env.language.task_box1
      ? env.language && env.language.task_box1
      : "Element";
  let task_box2 =
    env.language && env.language.task_box2
      ? env.language && env.language.task_box2
      : "Aufbewahrungsort";
  let task_box3 =
    env.language && env.language.task_box3
      ? env.language && env.language.task_box3
      : "Verstauen";

  return (
    <div onSubmit={handleSubmit} style={{ padding: "10px" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: textAreaWidth },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            value={element}
            type="text"
            onChange={handleChange1}
            placeholder={task_box1}
            multiline
            rows={3}
          />
        </div>
        <div>
          <TextField
            value={location}
            type="text"
            onChange={handleChange2}
            placeholder={task_box2}
            multiline
            rows={3}
          />
        </div>
        <div>
          <TextField
            value={resolution}
            type="text"
            onChange={handleChange3}
            placeholder={task_box3}
            multiline
            rows={3}
          />
        </div>
        <div>
          <Button variant="contained" onClick={handleSubmit}>
            {buttonTitle}
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default BocomboForm;
