import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faTrashAlt,
  faLocationArrow,
  faBox,
  faInfo,
  faClock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import BocomboForm from "./BocomboForm";
import { ModalStyle, ModalStyleFull } from "./Style";
import { getEnvironment } from "./Environment";

library.add(faEdit);
library.add(faTrashAlt);
library.add(faLocationArrow);
library.add(faBox);
library.add(faInfo);
library.add(faClock);
library.add(faUser);

const BocomboTask = ({ task, editTask, handleToggle, handleDelete }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    handleToggle(e.currentTarget.id);
  };

  const handleEditLink = (e) => {
    e.stopPropagation();
    setShowEditModal(true);
  };
  const handleDeleteLink = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const handleDeleteCall = () => {
    handleDelete(task.id);
  };

  const addTask = (userInput, location, resolution) => {
    editTask(task.id, userInput, location, resolution);
    setShowEditModal(false);
  };

  const env = getEnvironment();

  let icon1 =
    env.language && env.language.icon1
      ? env.language && env.language.icon1
      : "location-arrow";

  let icon2 =
    env.language && env.language.icon2
      ? env.language && env.language.icon2
      : "box";

  let icon0 =
    env.language && env.language.icon0
      ? env.language && env.language.icon0
      : "box";

  return (
    <>
      <Modal
        open={showDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Element entfernen?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ paddingBottom: `20px` }}>
              Soll das Element "{task.task}" entfernt werden?
            </div>
            <Button variant="contained" onClick={handleDeleteCall}>
              Ja
            </Button>
            <Button variant="outline" onClick={handleDeleteClose}>
              Nein
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={showEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyleFull}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editieren
          </Typography>
          <BocomboForm
            addTask={addTask}
            initValues={task}
            textAreaWidth="100%"
          />
          <Button variant="outline" onClick={handleEditClose}>
            Abbrechen
          </Button>
        </Box>
      </Modal>

      <div
        id={task.id}
        key={task.id + "task"}
        name="task"
        value={task.id}
        onClick={handleClick}
        className={task.complete ? "strike task" : "task"}
      >
        <div className={"task_task_hovered task_title"}>
          <span
            style={{
              width: `24px`,
              display: `inline-block`,
              textAlign: `center`,
            }}
          >
            <FontAwesomeIcon icon={icon0} style={{ paddingRight: `5px` }} />
          </span>
          {task.task}
        </div>
        <div
          className="task_location"
          style={{
            paddingTop: `5px`,
            display:
              typeof task.location === "undefined" || task.location === ""
                ? "none"
                : "",
          }}
        >
          <span
            style={{
              width: `24px`,
              display: `inline-block`,
              textAlign: `center`,
            }}
          >
            <FontAwesomeIcon icon={icon1} style={{ paddingRight: `5px` }} />
          </span>
          {task.location}
        </div>

        <div
          className="task_resolution"
          style={{
            paddingTop: `5px`,
            display:
              typeof task.resolution === "undefined" || task.resolution === ""
                ? "none"
                : "",
          }}
        >
          <span
            style={{
              width: `24px`,
              display: `inline-block`,
              textAlign: `center`,
            }}
          >
            <FontAwesomeIcon icon={icon2} style={{ paddingRight: `5px` }} />
          </span>
          {task.resolution}
        </div>

        <div
          className="task_options"
          // style={{ display: hovered ? "" : "none" }}
        >
          <span onClick={handleEditLink}>
            <FontAwesomeIcon icon="edit" /> Editieren
          </span>
          <span onClick={handleDeleteLink}>
            <FontAwesomeIcon icon="trash-alt" /> Löschen
          </span>
        </div>
      </div>
    </>
  );
};

export default BocomboTask;
