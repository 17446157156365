export const getEnvironment = () => {
  return {
    apiUrl: "https://liste.falkpost.de/list/api.php",
    redirectUrl: "https://liste.falkpost.de/list",
    version: "liste",
    language: {
      sublist: "1. ",
    },
  };
};
